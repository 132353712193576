<template>
  <div class="pay-container">
    <div class="title">
      确认付款
    </div>
    <div class="info">
      <p>确认为您的订单付款</p>
      <p>您的订单已创建，请在付款前确认您的订单无误</p>
    </div>
    <div class="pirce">
      <span>应付金额:</span>
      <span v-if="flag == 1 && orderAffirmInfo.paymethod != 1 && orderAffirmInfo.paystatus != 5">￥{{this.payActiveIndex !== 1?this.orderAffirmInfo.totalfee:this.orderAffirmInfo.deposit}}</span>
      <span v-if="flag != 1 && orderAffirmInfo.paymethod != 1 && orderAffirmInfo.paystatus != 5">￥{{this.payActiveIndex !== 1?this.orderAffirmInfo.price:this.orderAffirmInfo.deposit}}</span>
      <span v-if="orderAffirmInfo.ostatus === 190 && flag != 1">￥{{(orderAffirmInfo.price*1000 - orderAffirmInfo.deposit*1000)/1000 }}</span>
      <span v-if='orderAffirmInfo.paymethod == 1 && orderAffirmInfo.paystatus == 5'>{{orderAffirmInfo.price - orderAffirmInfo.haspaymoney}}</span>
    </div>

    <div class="handle-pay">
      <div class="select">
        <p class="handle-pay-title">平台支付:</p>
        <div class="btn-box">
          <div :class="(selectActiveIndex === 1 ? 'select-active' : '') + ' ' + 'btn'" @click="select(1)">
            <img src="@/assets/images/pay/微信支付.png" alt="">
          </div>
          <div :class="(selectActiveIndex === 2 ? 'select-active' : '') + ' ' + 'btn'" @click="select(2)">
            <img src="@/assets/images/pay/支付宝.png" alt="">
          </div>
        </div>
      </div>
      <div class="select">
        <p class="handle-pay-title" v-if="this.orderAffirmInfo.ostatus !== 190">支付方式（可选）</p>
        <p class="handle-pay-title" v-if="this.orderAffirmInfo.ostatus === 190">支付方式</p>
        <div class="btn-box btn-box-1">
          <div :class="(payActiveIndex === 0 ? 'pay-active' : '') + ' ' + 'btn'" @click="paySelect(0)" v-if="orderAffirmInfo.paymethod != 1 && orderAffirmInfo.paystatus != 5">全款支付</div>
          <div :class="(payActiveIndex === 1 ? 'pay-active' : '') + ' ' + 'btn'" @click="paySelect(1)" v-if="orderAffirmInfo.paymethod != 1 && orderAffirmInfo.paystatus != 5">定金支付</div>
           <div :class="(orderAffirmInfo.paymethod == 1 && orderAffirmInfo.paystatus == 5 ? 'pay-active' : '') + ' ' + 'btn'" @click="paySelect(2)" v-if="orderAffirmInfo.paymethod == 1 && orderAffirmInfo.paystatus == 5">支付尾款</div>
                  <div class="wenhao">
                    <img src="@/assets/images/pay/问号.png" class="issue-img" alt="">
                    <div class="btn-info">
                      伊莉丝为顾客提供多种付款方式，您可选择全款支付，也可以选择定金支付60%+尾款40%支付方式，祝您定制愉快
                    </div>
                  
                  
                  </div>
        </div>
      </div>
    </div>
    <div class="handle-footer">
      <div class="btn-box" v-if="this.orderAffirmInfo.ostatus !== 190">
        <p @click="pay">立即支付</p>
        <p @click="orderDetail(orderAffirmInfo.orderid)">订单详情</p>
      </div>

      <div class="btn-box ss" v-if="this.orderAffirmInfo.ostatus === 190">
        <p @click="pay">支付尾款</p>
      </div> 

      <p class="footer-info" v-if="this.orderAffirmInfo.ostatus !== 190 && flag == 1">请在下单后一小时内支付，否则订单会自动取消。将在<span> {{countDown}}自动取消</span></p>
    </div> 
  </div> 
</template>

<script>
import { WX_PAY, ALI_PAY } from "@/service/pay";

export default {
  data() {
    return {
      payActiveIndex: 0,
      selectActiveIndex: 1,
      countDown: '',
      orderAffirmInfo: {},
      flag:''
    }
  },

  filters: {
    amount(number) {
      return isNaN(number) ? 0.0 : parseFloat((number / 100).toFixed(2));
    }
  },

  created() {
    this.orderAffirmInfo = JSON.parse(this.$route.query.orderAffirmInfo)
    console.log(this.orderAffirmInfo)
    if(this.orderAffirmInfo.ostatus === 190){
      this.payActiveIndex = 2
    }
    if(this.orderAffirmInfo.haspaymoney != 0){
     this.orderAffirmInfo.haspaymoney =  parseFloat( (this.orderAffirmInfo.haspaymoney / 100).toFixed(2))
    }
    let minMs = this.orderAffirmInfo.closetime * 60 * 1000
    let orderct = this.orderAffirmInfo.orderct
    let targetTime = orderct + minMs
    setInterval(() => {
      this.countDown = this.filtersTime(targetTime)
    })
    if(this.orderAffirmInfo.price){
     let a = parseFloat( (this.orderAffirmInfo.price / 100).toFixed(2));
     this.orderAffirmInfo.price = a
    
     let b = parseFloat( (this.orderAffirmInfo.deposit / 100).toFixed(2));
     this.orderAffirmInfo.deposit = b
    }
    this.flag = this.$route.query.flag


  },

  methods: {
    paySelect(index) {
      this.payActiveIndex = index
    },

    select(index) {
      this.selectActiveIndex = index
    },

    /**
     * @title 支付
     */
    pay() {
      if(this.selectActiveIndex === 1) {
        WX_PAY({orderid: this.orderAffirmInfo.orderid, type: this.payActiveIndex}).then(res => {
          if(res.status === 200) {
            this.$store.dispatch('order/payInfo', res.attachment)
            this.$router.push({
              path: '/paycode',
              query: {
                payInfo: JSON.stringify({...res.attachment, orderid: this.orderAffirmInfo.orderid, type: this.payActiveIndex })
              }
            })
          }
        })
      }
      // 支付宝支付
      if(this.selectActiveIndex === 2) {
        ALI_PAY({orderid: this.orderAffirmInfo.orderid, type: this.payActiveIndex, hbfq: 0}).then(res => {
          if(res.status === 200) {
            if(res.attachment.form){
              var form = res.attachment.form;	 	
              const div=document.createElement('divform');                       
                    div.innerHTML=form;
                    document.body.appendChild(div);
                    document.forms[0].acceptCharset='utf-8';
                    document.forms[0].submit();
            }
          }
        })
      }
      
    },

    filtersTime(date) {
			let targetTime = new Date(date);
			let date1 = new Date();
      let ms = targetTime - date1;
      let d = Math.floor(ms/1000/3600/24);
      //获取小时数
      let h = Math.floor(ms%(3600*24*1000)/1000/3600);
      //获取分钟数
      let m = Math.floor(ms%(3600*24*1000)/1000%3600/60);
      //获取秒数
      let s = Math.floor(ms%(3600*24*1000)/1000%3600%60);

      d = d < 10 ? ("0" + d) : d;
      h = h < 10 ? ("0" + h) : h;
      m = m < 10 ? ("0" + m) : m;
      s = s < 10 ? ("0" + s) : s;
      return `${d}天${h}:${m}:${s}`
    },

    orderDetail(orderId) {
      this.$router.push({
        path: '/orderdetail',
        query: {
          orderId: orderId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ss p{
  margin-right: 0 !important;
}
.pay-container {
  width: 1200px;
  margin: 0 auto;
  .title {
    color: #666666;
    font-size: 30px;
    padding: 60px 0 35px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #cfcfcf;
  }
  .wenhao{
    display: flex;
    justify-content: space-around;
    align-items: center;
    img{
      width: 22px;
      height: 22px;
    }
    img:hover + div{
      display: block;
    }
    div{
      display: none;
    }
  }
  .info {
    width: 834px;
    margin: 0 auto;
    color: #666666;
    text-align: center;
    border-bottom: 1px solid #cfcfcf;
    padding: 76px 0 55px 0;
    box-sizing: border-box;
    p:nth-child(1) {
      font-size: 30px;
    }

    p:nth-child(2) {
      margin-top: 24px;
      font-size: 18px;
    }
  }
  .pirce {
    width: 834px;
    margin: 0 auto;
    padding: 46px 0;
    box-sizing: border-box;
    text-align: center;
    border-bottom: 1px solid #cfcfcf;
    span {
      font-size: 18px;
      color: #333333;
    }
    span:nth-child(2) {
      font-size: 36px;
      color: #000000;
      margin-left: 26px;
    }
  }

  .handle-pay {
    width: 834px;
    margin: 0 auto;
    padding: 50px 0 40px 0;
    box-sizing: border-box;
    .select {
      &:first-child {
        margin-bottom: 40px;
      }
      .handle-pay-title {
        font-size: 24px;
        color: #000000;
        margin-bottom: 20px;
      }
      .btn-box {
        display: flex;
        align-items: center;
        .btn {
          margin-right: 20px;
          width: 192px;
          height: 75px;
          border: 1px solid #cfcfcf;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            height: 100%;
          }
        }
        .btn-info {
          width: 474px;
          font-size: 14px;
          color: #666666;
          border-radius: 4px;
          background: #f5f5f5;
          padding: 10px 20px;
          box-sizing: border-box;
        }
        .select-active {
          border: 1px solid #333333;
        }
        
      }
      
      .btn-box-1 {
        width: 1200px;    
        .btn {
          background: #f5f5f5;
          color: #666666;
          font-size: 18px;
          border: none;
        }
        .pay-active {
          background: #e9d2d3;
          border: 1px solid #921d22;
          color: #921d22;
        }
        .issue-img {
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .handle-footer {
    width: 834px;
    margin: 0 auto;
    border-top: 1px solid #cfcfcf;
    padding: 50px 0 200px 0;
    .btn-box {
      display: flex;
      justify-content: center;
      p:first-child {
        margin-right: 100px;
      }
      p {
        width: 240px;
        height: 50px;
        background: #921d22;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .footer-info {
      margin-top: 50px;
      color: #333333;
      font-size: 16px;
      text-align: center;
      span {
        color: #921d22;
      }
    }
  }
}
</style>